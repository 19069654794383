import { getBaseUrl } from "./getBaseUrl";

const baseUrl = getBaseUrl();

export const joinWaitList = async (form) => {
  try {
    let resp = await fetch(`${baseUrl}/joinWaitList`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    });
    if (!resp.ok) {
      // console.log("Response not okay :(", resp.status);
      return resp.status;
    }

    //const data = await response.json();
    return resp.status;
  } catch (err) {
    console.log("Caught Error", err);
    return null;
  }
};
