const LOCAL_BACKEND_URL = "http://127.0.0.1:5555";
const PROD_BACKEND_URL = "https://api.spacecaptain.co";
const LOCAL_MODE = "development";
const PROD_MODE = "production";

export const getBaseUrl = () => {
  switch (process.env.NODE_ENV) {
    case LOCAL_MODE:
      return LOCAL_BACKEND_URL;
    case PROD_MODE:
      return PROD_BACKEND_URL;
    default:
      return LOCAL_BACKEND_URL;
  }
};
