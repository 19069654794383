<script setup>
import { onMounted, ref } from "vue";
import ModalLayerView from "@/views/ModalLayerView.vue";
import WaitListModal from "@/views/WaitListModal.vue";
import poiImage from "@/assets/poi_close_up.png";
import iPhoneAppImage from "@/assets/iphone_app.png";
import iPhoneAppStudioImage from "@/assets/iphone_app_studio.png";
import mixpanel from "mixpanel-browser";

onMounted(() => {
  console.log("Mounted");
  mixpanel.track("Home Visit");
});

const didJoin = (email) => {
  joinedEmail.value = email;
};

const showWaitList = ref(false);
const joinedEmail = ref(null);
</script>
<template>
  <div class="w-full items-center">
    <transition name="bounce" mode="out-in">
      <WaitListModal
        v-if="showWaitList"
        class="w-full h-full fixed top-0 left-0 z-20"
        @close="showWaitList = false"
        @didJoin="didJoin"
      >
      </WaitListModal>
    </transition>
    <transition
      enter-active-class="transition duration-150"
      enter-from-class="opacity-0"
      leave-active-class="transition duration-150"
      leave-to-class="opacity-0"
    >
      <ModalLayerView
        v-if="showWaitList"
        class="h-full w-full fixed top-0 left-0 z-10"
      ></ModalLayerView>
    </transition>

    <div class="w-full bg-gray-900 text-gray-200 py-2">
      <div class="text-5xl text-center font-thin">Honey Poi</div>
      <div class="text-xl text-gray-400 text-center italic font-light pt-1">
        $420 | Limited Prototype Availablity
      </div>
      <img :src="poiImage" class="w-[32rem] mx-auto" />
    </div>


    <div v-if="joinedEmail" class="max-w-5xl mx-auto px-4 py-8">
      <div class="text-3xl font-thin text-white pb-2">
        You're on the List! 🎉
      </div>
      <p class="text-gray-400">
        We'll send you an email to
        <span class="italic">{{ joinedEmail }}</span> with a link to purchase
        your set as soon as we have them in stock.
      </p>
      <p class="text-gray-400">Thank you for your interest in Honey Poi!</p>
    </div>
    <div v-else class="max-w-5xl mx-auto px-4 py-8">
      <div class="text-3xl font-thin text-white pb-2">Get HoneyPoi</div>
      <div class="text-gray-400">
        <p>
          A limited number of Honey Poi sets will be made this year. Join
          the wait list today to receive a notification when yours is available!
        </p>
        <p class="italic">
          This product is only available in the USA and the Honey Poi App is
          only available on iOS. An Android app is in the works.
        </p>

        <p class="pt-2">
          <button
            class="text-white bg-fuchsia-500 rounded-md p-2"
            @click="showWaitList = true"
          >
            Request Prototype
          </button>
        </p>
      </div>
    </div>

    <div class="max-w-5xl mx-auto px-4 pb-8">
      <div class="text-3xl font-thin text-white pb-2">
        Experience the Magic of Honey Poi
      </div>
      <div class="text-gray-400">
        Honey Poi is the perfect tool for performers and enthusiasts who want to
        take their poi spinning to the next level. Its unique HoneyComb display
        and app-controlled LED lights make it stand out from ordinary poi sets,
        and its endless possibilities for creative expression will keep you
        spinning for hours on end.
      </div>
    </div>

    <div class="w-full bg-gray-900 text-gray-800 py-6">
      <div
        class="max-w-5xl flex flex-wrap bg-gray-900 text-gray-800 py-2 mx-auto"
      >
        <img :src="iPhoneAppImage" class="w-48 mx-auto" />
        <div class="max-w-xl p-2 py-4">
          <div class="text-3xl font-thin text-white pl-2 pt-4 pb-2">
            360° HoneyComb Display
          </div>
          <div class="text-gray-400 pl-2">
            Honey Poi is more than just a set of LED poi - it's a performance in
            and of itself. With its mesmerizing 360 degree HoneyComb display and
            app-controlled LED lights, Honey Poi transforms any performance or
            dance routine into a stunning display of color and light.
          </div>
          <div class="text-3xl font-thin text-white pl-2 pt-8 pb-2">
            App Controlled<span class="text-sm italic pl-1">iOS only</span>
          </div>
          <div class="text-gray-400 pl-2">
            With the Honey Poi app, you can easily customize the color, pattern,
            and preferences of your poi, allowing you to create unique and
            eye-catching displays that will wow any audience. The app is simple
            and intuitive to use, making it easy for even beginners to create
            stunning displays.
          </div>
        </div>
      </div>
    </div>

    <div class="w-full bg-gray-900 text-gray-800 pb-6">
      <div
        class="max-w-5xl flex md:flex-nowrap flex-wrap bg-gray-900 text-gray-800 py-2 mx-auto"
      >
      <img :src="iPhoneAppStudioImage" class="w-48 mx-auto" />

        <div class="max-w-xl p-2 py-4">
          <div class="text-3xl font-thin text-white pl-2 pt-4 pb-2">
            Poi Studio
          </div>
          <div class="text-gray-400 pl-2">
            With Poi Studio, you can create detailed patterns directly in the app. As you craft 
            your design, live preview will bring your image to life in real time. Letting you 
            fine tune your patterns and colors, ensuring that every spin is just as you envisioned.
          </div>
        </div>

      </div>
    </div>

    <div class="max-w-5xl mx-auto px-4 pb-8 text-gray-300">
      <div class="text-3xl font-thin text-white pt-4">Features</div>
      <ul class="pl-4 pt-2 font-light text-gray-400">
        <li>➤ 6 Layer HoneyComb Display (128 Pixels)</li>
        <li>➤ Upload your own Images with the HoneyPoi App</li>
        <li>➤ Single button & motion gesture controls</li>
        <li>➤ 5-6 hour runtime (2000 mAh battery)</li>
        <li>
          ➤ Scorching Brightness Mode. Highly visible in shaded daylight. (30-45
          min runtime)
        </li>
      </ul>
      <div class="text-3xl font-thin text-white pt-4">Specs</div>
      <ul class="pl-4 pt-2 font-light text-gray-400">
        <li>➤ Weight: 190g</li>
        <li>➤ Knob Weight: 27g</li>
        <li>➤ Duel Core Processor 240 MHz</li>
        <li>➤ Bluetooth LE</li>
        <li>➤ 6 Axis accelerometer/gyro</li>
      </ul>

      <div class="text-3xl font-thin text-white pt-4">3 Year Warranty</div>
      <div class="pl-4 pt-2 font-light text-gray-400">
        <p>
          We stand behind the quality of our products, which is why we offer a
          3-year warranty for Honey Poi. If your Honey Poi set experiences any
          defects or malfunctions due and is not physically damaged within the
          first 3 years of purchase, we will repair or replace your set free of
          charge.
        </p>
        <p class="pt-2">
          Honey Poi uses a Li-ion battery that is only covered for the first 2
          years, after which a replacement can be purchased.
        </p>
        <p class="pt-2">
          Please note that the warranty does not cover damage caused by normal
          wear and tear, misuse, or accidents. Damaged parts outside warranty
          coverage can be repaired at a discount. Additionally, the warranty is
          non-transferable and only applies to the original purchaser of the
          product.
        </p>
        <p class="pt-2">
          At Space Captain, we are committed to providing our customers with
          high-quality products that will last. Order your Honey Poi set today
          with confidence, knowing that you are covered by our 3-year warranty.
        </p>
      </div>
    </div>
  </div>
</template>
