<template>
  <div class="container">
    <div class="support">
      <h2>FlowStick App Privacy Policy</h2>
      <br />
      <p>
        FlowStick app does not collect any personal information from any of your
        use of our Apps. If we have any of your personal information we will not
        willingly sell or give it to anyone, except if lawfully subpoenaed to
        produce it and then only after offering you a reasonable chance to
        challenge such subpoena if allowed by law.
      </p>
    </div>
    <img src="../assets/flowstick.png" class="support" />
  </div>
</template>

<style scoped>
.container {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

.support {
  padding: 50px;
  max-width: 750px;
  margin: 0 auto;
}
</style>
