<script setup>
import { ref, computed, onMounted } from "vue";
import mixpanel from "mixpanel-browser";
import { joinWaitList } from "@/composables/api";

const emit = defineEmits(["close", "didJoin"]);

const firstName = ref("");
const lastName = ref("");
const email = ref("");
const phoneType = ref("");
const experience = ref(null);
const experienceOptions = ["Beginner", "Advance", "Expert"];

const errorText = ref(null);

const isFormValid = computed(() => {
  return (
    validateEmail(email.value) &&
    phoneType.value &&
    experience.value &&
    firstName.value &&
    lastName.value
  );
});

onMounted(() => {
  mixpanel.track("Request Prototype");
});

const close = () => {
  emit("close");
};

const join = async () => {
  errorText.value = null;
  //mixpanel.track("Joined Wait List");
  let respStatus = await joinWaitList({
    FirstName: firstName.value,
    LastName: lastName.value,
    Email: email.value,
    Experience: experience.value,
    PhoneOS: phoneType.value,
  });
  if (respStatus === 200) {
    emit("didJoin", email.value);
    close();
  } else {
    mixpanel.track("Join Wait List Failed");
    errorText.value = "Oh no, something went wrong. Please try again later.";
  }
};

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
</script>
<template>
  <div>
    <div class="h-full w-full fixed top-0 left-0 z-20"></div>
    <div
      class="w-full max-w-md fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-30"
    >
      <div class="bg-gray-800 shadow-lg rounded-lg w-full">
        <div class="flex flex-row items-center px-2">
          <div class="text-gray-300 text-2xl font-thin m-2 w-full">
            Get Honey Poi
          </div>
          <div class="px-4 py-2 cursor-pointer" @click="close">╳</div>
        </div>

        <div class="p-4 flex flex-col gap-2">
          <div class="flex flex-row gap-4">
            <div class="w-full">
              <div class="text-gray-300 pb-2">First Name</div>
              <input
                v-model="firstName"
                name="firstName"
                type="text"
                autocomplete="given-name"
                class="text-white px-3 py-2 appearance-none rounded-md bg-gray-700 w-0 min-w-full"
              />
            </div>
            <div class="w-full">
              <div class="text-gray-300 pb-2">Last Name</div>
              <input
                v-model="lastName"
                name="lastName"
                type="text"
                autocomplete="family-name"
                class="text-white px-3 py-2 appearance-none rounded-md bg-gray-700 w-0 min-w-full"
              />
            </div>
          </div>
          <div class="text-gray-300">Email</div>
          <input
            v-model="email"
            name="email"
            type="email"
            autocomplete="off"
            class="text-white px-3 py-2 appearance-none rounded-md bg-gray-700"
          />

          <div class="text-gray-300 pt-2">Poi Experience Level</div>
          <div class="flex flex-row w-full">
            <button
              class="w-full p-2 px-3 cursor-pointer rounded-l-md border-gray-700 border-2"
              :class="experience === experienceOptions[0] ? 'bg-gray-700' : ''"
              @click="experience = experienceOptions[0]"
            >
              {{ experienceOptions[0] }}
            </button>
            <button
              class="w-full p-2 px-3 cursor-pointer border-gray-700 border-2 border-l-0"
              :class="experience === experienceOptions[1] ? 'bg-gray-700' : ''"
              @click="experience = experienceOptions[1]"
            >
              {{ experienceOptions[1] }}
            </button>
            <button
              class="w-full p-2 px-3 cursor-pointer rounded-r-md border-gray-700 border-2 border-l-0"
              :class="experience === experienceOptions[2] ? 'bg-gray-700' : ''"
              @click="experience = experienceOptions[2]"
            >
              {{ experienceOptions[2] }}
            </button>
          </div>
          <div class="text-gray-300 pt-2">Phone Type</div>
          <div class="flex flex-row w-full">
            <button
              class="w-full p-2 px-3 cursor-pointer rounded-l-md border-gray-700 border-2"
              :class="phoneType === 'iOS' ? 'bg-gray-700' : ''"
              @click="phoneType = 'iOS'"
            >
              iOS
            </button>
            <button
              class="w-full p-2 px-3 cursor-pointer rounded-r-md border-gray-700 border-2 border-l-0"
              :class="phoneType === 'Android' ? 'bg-gray-700' : ''"
              @click="phoneType = 'Android'"
            >
              Android
            </button>
          </div>
        </div>
        <div class="text-red-500 text-center w-full">
          {{ errorText }}
        </div>
        <div class="pt-2">
          <button
            type="submit"
            class="text-white rounded-b-md p-2 w-full"
            :class="isFormValid ? 'bg-fuchsia-500' : 'bg-gray-700'"
            @click="join"
            :disabled="!isFormValid"
          >
            Request Prototype
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.bounce-enter-active {
  animation: bounce-in 0.25s ease-out both;
}
.bounce-leave-active {
  animation: bounce-in 0.1s reverse ease-in both;
}

@keyframes bounce-in {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
</style>
