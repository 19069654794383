import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./index.css";
// import { Amplify } from "aws-amplify";
// import awsExports from "./aws-exports";
import mixpanel from "mixpanel-browser";

// Amplify.configure(awsExports);
mixpanel.init("4768b6a5d1106993ac572358b230ed13", {
  debug: true,
  ignore_dnt: true,
});

const app = createApp(App);
app.use(router);
app.mount("#app");

// Yes! The things I could do to a diabolo
