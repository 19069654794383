<template>
  <div class="container">
    <div class="support">
      <h2>FlowStick App Support</h2>
      <br />
      <p>
        For questions or support with the FlowStick app please email
        <a href="mailto: support@spacecaptain.co">support@spacecaptain.co</a>
        with FlowStick Support as the subject
      </p>
    </div>
    <img src="../assets/flowstick.png" class="support" />
  </div>
</template>

<style scoped>
.container {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

.support {
  padding: 50px;
  max-width: 400px;
  margin: 0 auto;
}
</style>
