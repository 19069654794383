<script>
import logo from "@/assets/logo.png";

export default {
  data: function () {
    return {
      logo: logo,
    };
  },
};
</script>

<template>
  <div class="bg-gray-800 min-h-screen text-white">
    <div class="bg-gray-900">
      <div class="flex flex-row items-center h-12">
        <router-link to="/" class="vert-center-content">
          <img :src="logo" class="appLogo" />
        </router-link>

        <!-- <div class="appBarMenu">
          <router-link to="/contact" class="text-white">
              <h3>Contact Us</h3>
          </router-link>
        </div> -->
      </div>
    </div>

    <div class="flex flex-col w-full items-center">
      <router-view></router-view>

      <div class="w-full p-2 text-gray-400 text-center">
        © Copyright 2024 Space Captain LLC |
        <router-link to="/privacy"> Privacy Policy </router-link>
        |
        <router-link to="/support"> Support </router-link>
      </div>
    </div>

    <!-- <footer>
      <div class="footer"></div>
    </footer> -->
  </div>
</template>

<style scoped>
.appBarContent {
  display: flex;
  flex-wrap: wrap;
  max-width: 1024px;
  height: 48px;
  margin-left: auto;
  margin-right: auto;
}

.appLogo {
  object-fit: cover;
  height: 20px;
  margin-left: 24px;
  margin-right: 24px;
}

.appBarMenu {
  display: flex;
  flex-wrap: wrap;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 24px;
}

.appBarMenu h3 {
  font-size: 1rem;
  font-weight: 500;
  padding: 6px;
  margin-right: 12px;
}
</style>
