<template>
  <div class="p-2">
    <div class="text-3xl font-light text-white pb-2">Support</div>
    <div>
      For support and information regarding beta testing please email us at
      <a href="mailto: support@spacecaptain.co">support@spacecaptain.co</a>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {};
  },
};
</script>
