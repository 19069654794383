import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import Contact from "@/views/ContactView.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import FlowStickView from "../views/FlowStickView.vue";
import FlowStickPrivacyPolicy from "../views/FlowStickPrivacyPolicy.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
    },
    {
      path: "/support",
      name: "support",
      component: Contact,
    },
    {
      path: "/data-request",
      name: "dataRequest",
      component: Contact,
    },
    {
      path: "/privacy",
      name: "privacyPolicy",
      component: PrivacyPolicy,
    },
    {
      path: "/flowstick/privacy",
      name: "flowstickprivacy",
      component: FlowStickPrivacyPolicy,
    },
    {
      path: "/flowstick",
      name: "flowstick",
      component: FlowStickView,
    },
  ],
});

export default router;
